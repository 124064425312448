// const tutorFormObject = localStorage.getItem('currentUser')
// const array = {  
//   "jwt": "",
//   "name": "",
//   "lastname": "",
//   "email": "",
//   "phone": "",
//   "avatar": {    
//     "src": "../images/avatar_area_box.png",
//     "upload": null,
//     "name": "user image"
//   },
//   "role": "",
//   "age": "",
//   "age_id": "",
//   "cities": "",
//   "cities_json": {},
//   "cities_id": "",
//   "degree": "",
//   "degree_bool": "",
//   "degree_id": "",
//   "districts": "",
//   "districts_id": "",
//   "education": "",
//   "education_bool": "",
//   "education_id": "",
//   "experience": "",
//   "experience_id": "",
//   "graduationyear": "",
//   "levels": "",
//   "levels_id": "",
//   "linkvideo": "",
//   "myplace": "",
//   "myplace_bool": "",
//   "online": "",
//   "price": "",
//   "price_id": "",
//   "sex": "",
//   "studentplace": "",
//   "subjects": "",
//   "subjects_json": {},
//   "subjects_id": "",
//   "yourself_and_activities": "",
// "classesschedule": []
// }
// if(localStorage.getItem('currentUser')!=="undefined"){
//   localStorage.setItem('currentUser', JSON.stringify(array))
// }
// const currentUserString = (localStorage.getItem('currentUser')!=="undefined")?localStorage.getItem('currentUser'):JSON.stringify(array);
// console.log(currentUserString)

const token = (localStorage.getItem('token') !== "undefined" && localStorage.getItem('token') !== null) ? localStorage.getItem('token') : '';
const currentUserObj = (localStorage.getItem('currentUser') !== "undefined" && localStorage.getItem('currentUser') !== null) ? JSON.parse(localStorage.getItem('currentUser')) : { "jwt": token, 'role': 0 };
console.log(localStorage.getItem('currentUser'))
console.log(currentUserObj)
console.log("+++++++++++++++++++++++++++++++++++++")

const initialState = {
    // currentUser: currentUser?JSON.parse(currentUser):"",
    currentUser: currentUserObj,
    selectedTutors: [], //{ "tutor": "ass" },
    selectUserOrders: { "current": "", "response": "" },
    error: '',
    message: ''
}

const reducer = (state = initialState, action) => {
    console.log("action.type");
    console.log(action.type);
    console.log(action.payload);
    switch (action.type) {
        case 'LOGIN_USER':

            console.log('LOGIN_USER')

            console.log(action.payload)
            console.log(state.currentUser)
            let obj = Object.assign({}, state.currentUser, action.payload);
            console.log(obj)
            localStorage.setItem('currentUser', JSON.stringify(obj));
            console.log('//LOGIN_USER')
            return {...state, currentUser: obj }
        case 'AVATAR':
            state.currentUser.avatar.src = "/handler/uploads/" + action.payload + ".png";
            localStorage.setItem('currentUser', JSON.stringify(state.currentUser));
            return {...state, currentUser: state.currentUser }
        case 'LOGOUT_USER':
            localStorage.setItem('token', null);
            localStorage.setItem('currentUser', JSON.stringify({ 'jwt': '', 'role': 0 }));
            return {...state, currentUser: { 'jwt': '', 'role': 0 } }
        case 'ERROR':
            return {...state, error: action.payload }
        case 'MESSAGE':
            return {...state, message: action.payload }
        case 'SWITCH_MENU':
            return {...state }
        case 'TUTOR_FORM':
            localStorage.setItem('currentUser', JSON.stringify(action.payload));
            return {...state, currentUser: action.payload }
        case 'SELECT_TUTORS':
            return {...state, selectedTutors: action.payload }
        case 'USER_ORDERS':
            console.log('~~~~~ reduser USER_ORDERS: ' + action.payload)
            state.selectUserOrders.response = action.payload
            return {...state, selectUserOrders: state.selectUserOrders }

        default:
            return state;
    }
}

export default reducer;