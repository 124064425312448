import React, { Component } from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { BrowserRouter as Router, Switch, NavLink, Link, Route, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';


class HowItWorks extends Component {
  componentDidMount = () => {
  }
  render() {
    return (
      <div className="main_wrapper">
        <div className="main_wrapper_block">
          <div className="main_wrapper_block_content_stretch">
            <h1><FormattedMessage id="public_offer" /></h1>
            <p>Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую сайт "Goal", расположенный по адресу - goal. ???, может получить о Пользователе во время использования данного
    сайта.</p>
            <h2>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</h2>
            <p>1.1 В настоящей Политике конфиденциальности используются следующие термины:</p>
            <p>1.1.1. «Администрация сайта "Goal" (далее – Администрация сайта) » – уполномоченные сотрудники на управления сайтом, которые организовуют и осуществляют обработку персональных данных, а также определяют цели обработки персональных данных, состав персональных
    данных, подлежащих обработке, действия или операции, совершаемые с персональными данными.</p>
            <p>1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).</p>
            <p>1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию,
    накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
            <p>1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Администратором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного
    законного основания.</p>
            <p>1.1.5. «Пользователь сайта "Goal"» – лицо, имеющее доступ к Сайту, посредством сети Интернет. А именно это или репетитор, или ученик, ищущий себе подходящего преподавателя.</p>
            <p>1.1.6. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</p>
            <h2>2. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
            <p>2.1. Использование Пользователем сайта "Goal" означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.</p>
            <p>2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта "Goal".</p>
            <p>2.3.Настоящая Политика конфиденциальности применяется только к сайту "Goal", который не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте.</p>
            <p>2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта "Goal".</p>
            <h2>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</h2>
            <p>3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта при регистрации
    на "Goal" для получения услуг сайта.</p>
            <p>3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения регистрационной формы на Сайте "Goal" в разделе о регистрации и включают в себя следующую информацию:</p>
            <p>3.2.1. ФИО Пользователя;</p>
            <p>3.2.2. контактный телефон Пользователя;</p>
            <p>3.2.3. адрес электронной почты (e-mail);</p>
            <p>3.2.4. документы, подтверждающие образование, квалификацию, степень подготовки;</p>
            <p>3.2.5. место жительство;</p>
            <p>3.2.6. пол, возраст Пользователя.</p>
            <h2>4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</h2>
            <p>4.1. Персональные данные Пользователя Администрация сайта может использовать в целях:</p>
            <p>4.1.1. Идентификации Пользователя, зарегистрированного на сайте, для получения услуг по поиску учеников и репетиторов.</p>
            <p>4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта.</p>
            <p>4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработка запросов и заявок от Пользователя.</p>
            <p>4.1.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.</p>
            <p>4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.</p>
            <p>4.1.6. Создания учетной записи для оказания определенных услуг, если Пользователь дал согласие на создание учетной записи.</p>
            <p>4.1.7. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием Сайта.</p>
            <h2>5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</h2>
            <p>5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</p>
            <p>5.2. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные только преподавателям для осуществления связи с учеником.</p>
            <p>5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Украины только по основаниям и в порядке, установленным законодательством Украины.</p>
            <p>5.4. При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате или разглашении персональных данных.</p>
            <p>5.5. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от
    иных неправомерных действий третьих лиц.</p>
            <p>5.6. Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.</p>
            <h2>6. ОБЯЗАТЕЛЬСТВА СТОРОН</h2>
            <p>6.1. Пользователь обязан:</p>
            <p>6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом "Goal".</p>
            <p>6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.</p>
            <p>6.2. Администрация сайта обязана:</p>
            <p>6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.</p>
            <p>6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных
    данных Пользователя.</p>
            <p>6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.</p>
            <p>6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на
    период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.</p>
            <h2>7. ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
            <p>7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Украины, за исключением случаев, предусмотренных
    п.п. 5.2., 7.2. настоящей Политики Конфиденциальности.</p>
            <p>7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, если данная конфиденциальная информация:</p>
            <p>7.2.1. Стала публичным достоянием до её утраты или разглашения.</p>
            <p>7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.</p>
            <p>7.2.3. Была разглашена с согласия Пользователя.</p>
            <h2>8. РАЗРЕШЕНИЕ СПОРОВ</h2>
            <p>8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).</p>
            <p>8.2 .Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</p>
            <p>8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством Украины.</p>
            <p>8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее законодательство Украины.</p>
            <h2>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h2>
            <p>9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.</p>
            <p>9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.</p>
            <p>9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать в раздел "КОНТАКТЫ".</p>
            <p>9.4. Действующая Политика конфиденциальности размещена на странице по адресу "goal ?????/privacy/".</p>
          </div>
        </div>
      </div>
    );
  }
}



const mapStateToProps = state => ({
  currentUser: state.currentUser
  //currentUser: state.reducer.currentUser
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(HowItWorks);



