import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import findGetParameter from '../components/FindGetParameter';


class Profile extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: true,
        id: findGetParameter("id"),
        profile: {},
        close_popup: [true]
    }
    getProfile = () => {
        const id = findGetParameter("id")
        if (id) {
            const fetch_url = "https://superprof.spilka.dp.ua"
            fetch(fetch_url + "/handler/get_profile.php?id=" + id + "", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'jwt': `${this.props.currentUser.jwt}`
                }
            })
                .then(resp => resp.json())
                .then(data => {
                    if (data.error) {
                        // Будет ошибка если token не дествительный
                        console.log(data.error)
                        this.setState({ formError: this.props.intl.formatMessage({ id: data.error }) })
                    } else {
                        console.log(data.message)
                        console.log(data.profile)
                        this.setState({ load: false, profile: data.profile })
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            return <Redirect to={`/`} />
        }
    }

    componentDidMount() {
        // this.getProfile()
    }

    render() {

        return (
            <main className="main_wrapper_block">
            </main>
        )
    }
}
const mapStateToProps = state => ({
    currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Profile));