import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProfileFetch, logoutUser, switchMenu } from '../redux/actions';
import { Redirect } from 'react-router-dom';


class Logout extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = () => {
    this.props.getProfileFetch()
  }
  state={
    redirect: false
  }

  handleClick = event => {
    event.preventDefault()
    // Удаление token из localStorage
    localStorage.removeItem("token")
    // удаление из Redux хранилица
    this.props.logoutUser()
   console.log("Redirect to=/")
   this.setState({redirect: true})
  }
  render() {
    return (this.props.currentUser.jwt) ? (
      <div className="drop_menu">
        {(this.state.redirect)?<Redirect to="/" />:''}
        <div className="drop_menu-current">
          {(this.props.currentUser.avatar.src)?
          <div className="avatar_preview" style={{backgroundImage: "url('"+this.props.currentUser.avatar.src+"')"}} />:
          <img className="avatar_preview"  src="./images/users_info_box_no_images-min.png" />}
          <span className="arrow">
            <svg id="Capa_1" x="0px" y="0px" viewBox="0 0 477.2 252.2">
              <g>
                <path className="st0" d="M238.6,219.5L23.1,4C17.8-1.3,9.3-1.3,4,4s-5.3,13.8,0,19.1l225.1,225.1c5.3,5.3,13.8,5.3,19.1,0l225-225.1c2.6-2.6,4-6.1,4-9.5s-1.3-6.9-4-9.5c-5.3-5.3-13.8-5.3-19.1,0L238.6,219.5z"></path>
              </g>
            </svg>
          </span>
        </div>
        <ul className="drop_menu-dropdown">
        <li><FormattedMessage id={"role."+this.props.currentUser.role} /></li>
        {(this.props.currentUser.role == 3) ? <>
            <li><Link className="b_menu__item" to={`/dashboard?tab=new_orders`}><FormattedMessage id="new_orders" /></Link></li>
            <li><Link className="b_menu__item" to={`/dashboard?tab=chats`}><FormattedMessage id="chat_rooms" /></Link></li>
            <li><Link className="b_menu__item" to={`/tutors`}><FormattedMessage id="search" /></Link></li>
            <li><Link className="b_menu__item" to={`/dashboard?tab=my_profile`}><FormattedMessage id="client_profile" /></Link></li>
            <li><Link className="b_menu__item" to={`/dashboard?tab=paid_orders`}><FormattedMessage id="archive_of_orders" /></Link></li>
            <li><Link className="b_menu__item" to={`/`}><FormattedMessage id="questions_and_answers" /></Link></li>
          </> : ''}

        {(this.props.currentUser.role == 2) ? <>
            <li><Link className="b_menu__item" to={`/dashboard?tab=new_orders`}><FormattedMessage id="new_orders" /></Link></li>
            <li><Link className="b_menu__item" to={`/dashboard?tab=chats`}><FormattedMessage id="chat_rooms" /></Link></li>
            <li><Link className="b_menu__item" to={`/tutors`}><FormattedMessage id="search" /></Link></li>
            <li><Link className="b_menu__item" to={`/dashboard?tab=my_profile`}><FormattedMessage id="client_profile" /></Link></li>
            <li><Link className="b_menu__item" to={`/dashboard?tab=paid_orders`}><FormattedMessage id="archive_of_orders" /></Link></li>
            <li><Link className="b_menu__item" to={`/`}><FormattedMessage id="questions_and_answers" /></Link></li>
          </> : ''}

          {(this.props.currentUser.role == 1) ? <>
            <li><Link className="b_menu__item" to={`/dashboard?tab=adopted_orders`}><FormattedMessage id="my_orders" /></Link></li>
            <li><Link className="b_menu__item" to={`/dashboard?tab=new_orders`}><FormattedMessage id="new_orders" /></Link></li>
            <li><Link className="b_menu__item" to={`/dashboard?tab=my_profile`}><FormattedMessage id="my_profile" /></Link></li>
            {/* <li><Link className="b_menu__item" to={`/`}><FormattedMessage id="my_rating_/_TOP" /></Link></li>
            <li><Link className="b_menu__item" to={`/`}><FormattedMessage id="wallet_and_payments" /></Link></li> */}
            <li><Link className="b_menu__item" to={`/`}><FormattedMessage id="my_blogs" /></Link></li>
            <li><Link className="b_menu__item" to={`/`}><FormattedMessage id="affiliate_program" /></Link></li>
            <li><Link className="b_menu__item" to={`/`}><FormattedMessage id="information_and_conditions" /></Link></li>
            <li><Link className="b_menu__item" to={`/`}><FormattedMessage id="how_does_the_service_work" /></Link></li>
          </> : ''}

          {(this.props.currentUser.role == 0) ? <>
            <li><Link className="b_menu__item" to={`/dashboard?tab=adopted_orders`}><FormattedMessage id="my_orders" /></Link></li>
            <li><Link className="b_menu__item" to={`/dashboard?tab=chats`}><FormattedMessage id="chat_rooms" /></Link></li>
            <li><Link className="b_menu__item" to={`/tutors`}><FormattedMessage id="search" /></Link></li>
            <li><Link className="b_menu__item" to={`/dashboard?tab=my_profile`}><FormattedMessage id="client_profile" /></Link></li>
            <li><Link className="b_menu__item" to={`/dashboard?tab=paid_orders`}><FormattedMessage id="archive_of_orders" /></Link></li>
            <li><Link className="b_menu__item" to={`/`}><FormattedMessage id="questions_and_answers" /></Link></li>
          </> : ''}

          <li><button onClick={this.handleClick}><FormattedMessage id="app.log_out" /></button></li>
        </ul>
      </div>
      // <div id="menu_icon" onClick={this.handleClick}><FormattedMessage id="app.log_out"/></div>
    ) : (
      <div className="drop_menu">
        <div className="drop_menu-current">
          <Link to={`/login`}><FormattedMessage id="app.login" /></Link>
          {/* <span className="arrow">
                  <svg id="Capa_1" x="0px" y="0px" viewBox="0 0 477.2 252.2">
                      <g>
                          <path className="st0" d="M238.6,219.5L23.1,4C17.8-1.3,9.3-1.3,4,4s-5.3,13.8,0,19.1l225.1,225.1c5.3,5.3,13.8,5.3,19.1,0l225-225.1c2.6-2.6,4-6.1,4-9.5s-1.3-6.9-4-9.5c-5.3-5.3-13.8-5.3-19.1,0L238.6,219.5z"></path>
                      </g>
                  </svg>
              </span> */}
        </div>
        {/* <ul className="drop_menu-dropdown">
          <li><button  ><FormattedMessage id="app.login" /></button></li>
          <li><button  ><FormattedMessage id="app.login" /></button></li>
          <li><button  ><FormattedMessage id="app.login" /></button></li>
        </ul> */}
      </div>
      // <div id="menu_icon"><Link to={`/login`}><FormattedMessage id="app.login"/></Link></div>
    );
  }
}




{/* <ul className="">
<li><Link className="b_menu__item" to={`/tutors`}><FormattedMessage id="app.tutors" /></Link></li>
<li><Link className="b_menu__item" to={`/how_it_works`}><FormattedMessage id="app.how_it_works" /></Link></li>
<li><Link className="b_menu__item" to={`/register_tutor`}><FormattedMessage id="app.become_a_tutor" /></Link></li>
<li><Link className="b_menu__item" to={`/public_offer`}><FormattedMessage id="public_offer" /></Link></li>
<li><Link className="b_menu__item" to={`/privacy`}><FormattedMessage id="privacy" /></Link></li>
</ul>
<ul className="">
<li><Link className="b_menu__item" to={`/profile?id=${"51"}`}>tutors 51</Link></li>
<li><Link className="b_menu__item" to={`/tutors?cities=${"A cities" + 1}&subjects=${"A subjects" + 1}`}>tutors</Link></li>
<li><Link className="b_menu__item" to={`/register_tutor`}>Signup Tutors</Link></li>
<li><Link className="b_menu__item" to={`/data_tutor`}>Tutor Data</Link></li>
<li><Link className="b_menu__item" to={`/login`}>login</Link></li>
</ul>
<ul className="">
<li><Link className="b_menu__item" to={`/contacts`}>contacts</Link></li>
<li><Link className="b_menu__item" to={`/orders`}>orders</Link></li>
<li><Link className="b_menu__item" to={`/chat`}>chat</Link></li>
</ul> */}



const mapStateToProps = state => ({
  currentUser: state.currentUser
  //currentUser: state.reducer.currentUser
})

const mapDispatchToProps = dispatch => ({
  getProfileFetch: () => dispatch(getProfileFetch()),
  logoutUser: () => dispatch(logoutUser()),
  switchMenu: () => dispatch(switchMenu())
})

export default connect(mapStateToProps, mapDispatchToProps)(Logout);

